import React from 'react'
import { useTranslation } from 'react-i18next';

const UsFlag = <img src="/countryflags/us1.svg" style={{ width: '32px', height: '20px' }} alt="US" />;
const TaiwanFlag = <img src="/countryflags/taiwan.svg" style={{ width: '32px', height: '20px' }} alt="CN" />;

const languages = [
    { lang: "en", flag: UsFlag, text: "English(US)" },
    { lang: "tw", flag: TaiwanFlag, text: "中文 (繁體)" }
];

const LanguageSelector = () => {
    const { i18n } = useTranslation("authentication");

    let found = languages.find(obj => obj.lang === i18n.language);
    if (!found) {
        i18n.changeLanguage("en");
    }

    return (
        <div className="lang-selector w-100 p-1">
            <button className="btn  p-0 mt-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <span className='px-1 py-0' style={{ background: '#002c96', borderRadius: '50%',}}>
                    <i className="bi bi-globe text-white" style={{ fontSize: 15.5}}></i>
                </span>
            </button>
            <ul className="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                {
                    languages.map((v, k) => {
                        return (
                            <li
                                key={k}
                                className="p-1 d-flex align-items-center"
                                style={{ background: v.lang === i18n.resolvedLanguage ? 'rgba(13,110,253,0.25)' : 'none', cursor: 'pointer', height: 40 }}
                                onClick={()=> i18n.changeLanguage(v.lang)}
                            >
                                {v.flag}<span className='mx-1'>{v.text}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default LanguageSelector;